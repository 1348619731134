import React from "react"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
//import fontawesome
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import "./top-player.scss"
export default function TopPlayer({playerData}) {

    return (
        <>
            {/* <!-- Our Team Start Here--> */}
            <div className="our-team-section pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <h3 className="title-bg">Top Players</h3>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, Mousewheel]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                375: {
                                    slidesPerView: 1,
                                },
                                425: {
                                    slidesPerView: 1,
                                },
                                768: { slidesPerView: 2 },
                                1024: { slidesPerView: 4 },
                            }}
                            slidesPerView={4}
                            loop={true}
                            spaceBetween={30}
                            pagination={false}
                            navigation={false}
                            autoplay={true}
                            mousewheel={false}
                            keyboard={true}
                            className="award-achivement-swiper"
                        >   
                        {playerData?.map(el => {
                            return (
                                <SwiperSlide>
                                <div className="our-team">
                                    <GatsbyImage
                                    image={getImage(el?.team?.avatar?.localFile)}
                                    alt={"Match Image"}
                                    className="img-top-player"
                                    />
                                    <div className="person-details">
                                        <div className="overly-bg"></div>
                                        <a href="team-single.html"><h5 className="person-name">{el?.title}</h5></a>
                                        <table className="person-info">
                                            <tbody>
                                                <tr>
                                                    <td>Nationality</td>
                                                    <td style={{display: "flex", height: "27px", alignItems: "center"}}>
                                                    <GatsbyImage
                                                    image={getImage(el?.team?.countryFlag?.localFile)}
                                                    alt={"Nationality"}
                                                    className="mb-flag"
                                                    />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Born</td>
                                                    <td>{el?.team?.birthday}</td>
                                                </tr>
                                                <tr>
                                                    <td>Position</td>
                                                    <td>{el?.team?.position}</td>
                                                </tr>
                                                <tr>
                                                    <td>Club</td>
                                                    <td>{el?.team?.teamName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Squad Number</td>
                                                    <td>{el?.team?.number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Follow us on</td>
                                                    <td>
                                                    <ul className="person-social-icons">
                                                        {el?.team?.facebookLink ? 
                                                            <li>
                                                            <a className="active" href={`${el?.team?.facebookLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faFacebook}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                        {el?.team?.twitterLink ? 
                                                            <li>
                                                            <a className="active" href={`${el?.team?.twitterLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faTwitter}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                        {el?.team?.instagramLink ? 
                                                            <li>
                                                            <a className="active" href={`${el?.team?.instagramLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faInstagram}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                        {el?.team?.linkedinLink ? 
                                                            <li>
                                                            <a className="active" href={`${el?.team?.linkedinLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faLinkedin}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                    </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SwiperSlide>
                            )
                        })}
                        </Swiper>
                    </div>
                </div>
            </div>
            {/* <!-- Our Team end Here--> */}
        </>
    )

}