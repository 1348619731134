import React from "react"
import AboutTeam from "../components/about-team/about-team"
import ReportLanding from "../components/report-landing/report-landing"
import GalleryMatch from "../components/gallery-match/gallery-match"
import TopPlayer from "../components/top-player/top-player"

const components = ({}) => {

  return (
    <>
    {/* your component here */}

    <GalleryMatch/>
    <ReportLanding/>
     <AboutTeam/>
    <TopPlayer/>
    </>
  ) 



}

export default components
