import React, {useState, useEffect} from "react"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
//import fontawesome
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./gallery-match.scss"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

export default function GalleryMatch({matchData, galleryTitle}) {

    const title = galleryTitle ? galleryTitle : "Gallery";

    const images = matchData?.matchImages.map(e => e.matchImage?.localFile?.publicURL);
    // console.log("array", imageData);

    const [isOpen, setIsOpen] = useState(0);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        AOS.init({
          duration: 2000
        });
        AOS.refresh();
      }, []);

    return (
        <>
            {/* <!-- Gallery Section Start Here--> */}
            <div className="gallery-section-area-raider pb-70" style={{paddingTop: "100px"}}>
                <div className="container" id="grid">
                    <h3 className="title-bg">{title}</h3>
                    <div id="gallery-items">
                        <div className="row align-items-center">
                            {matchData?.matchImages.map(el => {
                                return (
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-gallery"
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-delay="5"
                                    >
                                        <GatsbyImage
                                            image={getImage(el?.matchImage.localFile)}
                                            alt={"Inner Hero Image"}
                                            className="img-gallery"
                                        />
                                        <div className="heading-conent">
                                            <h4>{el?.matchTitle}</h4>
                                        </div>
                                        {/* <div className="bottom-icons">
                                            <ul>
                                                <li>
                                                    <Link className="" href="#">
                                                        <FontAwesomeIcon
                                                            className="icon-gallery-match"
                                                            icon={faInstagram}
                                                            style={{width: "15px", height: "15px"}}
                                                        />
                                                    </Link>
                                                </li>
                                                <li>                                             
                                                    <FontAwesomeIcon
                                                        className="icon-gallery-match"
                                                        icon={faPowerOff}
                                                        onClick={() => [setIsOpen(true), setPhotoIndex(matchData?.matchImages?.indexOf(el))]}
                                                        style={{cursor:"pointer", width: "15px", height: "15px"}}
                                                    />   
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                )
                            })}
                            {!!isOpen && (
                                <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={
                                    images[
                                    (photoIndex + images.length - 1) % images.length
                                    ]
                                }
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex(
                                    (photoIndex + images.length - 1) % images.length
                                    )
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % images.length)
                                }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Gallery Section End Here--> */}
        </>
    )
}